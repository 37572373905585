<template>
    <div class="flex min-h-screen items-center justify-center">
        <div class="w-full max-w-xs px-2 sm:px-0">
            <router-link :to="{name: 'register'}"><img alt="Boothclub" src="../assets/images/Boothclub-logo.png"></router-link>
            <h1 class="text-50px font-bold tracking-4px mt-6">REGISTER</h1>
            <form autocomplete="off" v-on:submit.prevent="passwordLogin" method="post" v-if="!logged_in">
                <p class="text-lg font-work-sans-medium font-bold tracking-widerm mb-4">This page is password protected. Please enter username and password to continue registeration.</p>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Username
                        <input type="text" name="auth_username" id="auth_username" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="auth_username" placeholder="Username">
                    </label>
                </div>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Password
                    <input type="password" name="auth_password" id="auth_password" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="auth_password" placeholder="Password">
                    </label>
                </div>
                <div>
                    <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise" type="submit">LOGIN</button>
                </div>
                <div class="text-red-600 text-2xl font-dense-medium tracking-wider text-center pt-2" v-if="auth_error">
                    Invalid Username/Password
                </div>
            </form>
            <form autocomplete="off" v-on:submit.prevent="registerUser" method="post" v-if="logged_in">
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Company Name
                        <input type="text" name="company_name" id="company_name" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="company_name" placeholder="Company Name">
                    </label>
                </div>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Email Address
                        <input type="email" name="email_address" id="email_address" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="email_address" placeholder="Email Address">
                    </label>
                </div>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Password
                        <input type="password" name="password" id="password" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="password" placeholder="Password" minlength="8">
                    </label>
                </div>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Confirm Password
                        <input type="password" name="confirm_password" id="confirm_password" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required placeholder="Confirm Password" minlength="8" :pattern="password" title="Please match confirm password with the password entered above">
                    </label>
                </div>
                <div class="relative mb-4">
                    <label class="text-2xl uppercase font-bold tracking-wider"> Custom Sender Email Address
                        <input type="email" name="sender_email_address" id="sender_email_address" class="bg-black w-full outline-none border border-gray-custom-2 pb-2 font-work-sans-medium  tracking-wider text-lg p-1" required v-model="sender_email_address" placeholder="Custom Sender Email Address">
                    </label>
                </div>
                <div>
                    <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise" type="submit">SIGN UP</button>
                </div>
                <div class="text-red-600 text-2xl font-dense-medium tracking-wider text-left pt-2 leading-none" v-if="error">
                    {{error_msg}}
                </div>
                <div class="text-green-500 text-2xl font-dense-medium tracking-wider text-left pt-2 leading-none" v-if="success">
                    Registration successful. An email confirmation has been sent, please verify your email.
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Register',
        data: function() {
            return {
                auth_username: null,
                auth_password: null,
                auth_error: false,
                logged_in: false,
                company_name: null,
                email_address: null,
                password: null,
                sender_email_address: null,
                error: false,
                error_msg: '',
                success: false,
            }
        },
        methods: {
            passwordLogin: function() {
                var app = this;
                this.$http({
                    url: 'register/auth',
                    method: 'post',
                    auth: {
                        username: this.auth_username,
                        password: this.auth_password
                    }
                }).then(function() {
                    app.auth_error = false;
                    app.logged_in = true;
                }).catch(function() {
                    app.auth_error = true;
                    app.logged_in = false;
                });
            },
            registerUser: function() {
                var app = this;
                this.error = false;
                this.error_msg = '';
                this.success = false;
                this.$http({
                    url: 'register/signup',
                    method: 'post',
                    auth: {
                        username: this.auth_username,
                        password: this.auth_password
                    },
                    data: {
                        company_name: this.company_name,
                        email_address: this.email_address,
                        password: this.password,
                        sender_email_address: this.sender_email_address
                    }
                }).then(function() {
                    app.company_name = app.email_address = app.password = app.sender_email_address = '';
                    app.success = true;
                    setTimeout(function() {
                        app.$router.push({name: 'signin'});
                    }, 6000);
                }).catch(function(err) {
                    app.error = true;
                    if( typeof err.response.data.message !== 'undefined' ) {
                        if (err.response.data.message && err.response.data.message.match(/Password did not conform with policy:/gmi)) {
                            app.error_msg = 'Password Strength requirements: Password must include at least one upper case, lower case, number and symbol.';
                        } else {
                            app.error_msg = err.response.data.message;
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>